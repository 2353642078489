import React, { useEffect } from "react";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupIcon from "@mui/icons-material/Group";
import BuildIcon from "@mui/icons-material/Build";
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import StorageIcon from "@mui/icons-material/Storage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SidebarOption from "../page components/SidebarOption";
import SidebarOptionDropDown from "../page components/SidebarOptionDropDown";
import "../page css components/Sidebar.css";
import logo from "../Drawables/Logos/eCraft_logo_main.png";
import { useAppSelector } from "../state/hooks";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";

function Sidebar() {
  const ActiveTab = useAppSelector((state) => state.active_tab);

  return (
    <div className="sidebar">
      {/* <div className="header">
      <img  className ="logo" src={logo} alt ="eCraftIndia Admin"/>
      </div> */}

      <div>
        {/* <SidebarOption
          title="Dashboard"
          tab="dashboard"
          routePath="Dashboard"
        />
        {ActiveTab == "dashboard" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        <SidebarOption
          title="Manufacturing Dashboard"
          tab="Manufacturing_dashboard"
          routePath="ManufacturingDashboard"
        />
        {ActiveTab == "Manufacturing_dashboard" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )} */}

        <SidebarOption
          title="Listings"
          tab="listings"
          routePath="/listings/all"
        />
        {ActiveTab == "listings" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        {/* <SidebarOption title="Vendors" tab="vendors" routePath="/vendors/all" />
        {ActiveTab == "vendors" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )} */}

        <SidebarOption
          title="Invoices"
          tab="invoices"
          routePath="/invoices/all"
        />
        {ActiveTab == "invoices" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        <SidebarOption title="Stock & Sales Reports" tab="snsreports" />
        {ActiveTab == "snsreports" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        <SidebarOption title="Recievables Challans" tab="challans" />
        {ActiveTab == "challans" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        <SidebarOption title="Audit Challans" tab="audit-challans" />
        {ActiveTab == "audit-challans" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        {/* <SidebarOption
          title="Purchase Orders"
          tab="purchase-orders"
          routePath="/purchase-orders/all"
        />
        {ActiveTab == "purchase-orders" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )} */}

        {/* 
        <SidebarOptionDropDown title="Manage users" />
        <div className="underline"></div>

        <SidebarOption
          title="Manufacturing Team"
          routePath="RegularManufacturingForm"
          tab="manufacturing"
        />
        {ActiveTab == "manufacturing" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        <SidebarOption
          title="Bulk Ops Team "
          routePath="BulkOperationsForm"
          tab="bulk_ops"
        />
        {ActiveTab == "bulk_ops" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )} */}

        {/* <SidebarOption
          title="Regular Ops Team"
          routePath="RegularOperationsForm"
          tab="regular"
        />
        {ActiveTab == "regular" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        <SidebarOption
          title="Companies"
          routePath="Companies"
          tab="Companies"
        />
        {ActiveTab == "Companies" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )}

        <SidebarOption
          title="Groups/Teams"
          routePath="ManageGroupsAndTeams"
          tab="Groups/Teams"
        />
        {ActiveTab == "Groups/Teams" ? (
          <div className="underline_active"></div>
        ) : (
          <div className="underline"></div>
        )} */}

        {/* <div className="log_out" onClick={()=>handleClickOpen()}>
            <div ><ExitToAppIcon className="log_out_icon"/></div>
            <div className="log_out_name">Logout</div>         
          </div> */}
      </div>
    </div>
  );
}

export default Sidebar;
