import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../page css components/Sidebaroption.css";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../state/hooks";
import { AppDispatch } from "../state";
import { ActionType } from "../state/action-types";
import { ActiveTab__Action } from "../state/action-creators";

type Props = {
  title: string;
  Icon?: any;
  tab: string;
  routePath?: string;
};

function SidebarOption(props: Props) {
  const history = useHistory();
  // const dispatch = useAppDispatch();
  const dispatch: AppDispatch = useDispatch();

  const navigate = () => {
    if (props.routePath) {
      history.push(props.routePath);
    } else {
      alert("Route path not available for this item yet");
    }
  };

  return (
    <div
      className="sidebarOption"
      id="side"
      onClick={() => {
        navigate();
        dispatch(ActiveTab__Action(props.tab));
      }}
    >
      {props.Icon && <div className="sidebarOption__icon">{props.Icon}</div>}
      <div className="sidebarOption__name">{props.title}</div>
    </div>
  );
}

export default SidebarOption;
